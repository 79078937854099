ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.app {
  overflow: auto;
}

.btnTest {
  z-index: 999;
  cursor: pointer;
}
