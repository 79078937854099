@value unosGrey, unosBlack, unosunoslightBlack, unosLightGrey from '../../common/colors.css';
@value baseSpace from '../../common/size.css';

.mainWrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.wrapper {
  width: 100%;
  background-color: antiquewhite;
  position: relative;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.info {
  border-bottom: 1px solid unosLightGrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

.searchBox {
  display: flex;
  justify-content: space-between;
}

/* tabs */
.tabWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
}

.tabs {
  width: 100%;
}

/* searchCondition */
.searchCondition {
  padding: 0.8rem 12px;
}

.conditionRow {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 0 0;
}

.select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px;
}

/* datepicker */
.datePickerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.datePickerTitle {
  margin-right: 1rem;
}

.datePickerBox {
  width: fit-content;
  min-width: 283px;
  max-height: 34px;
  border: 1px solid unosLightGrey;
  border-radius: 12px;
  padding: 0 4px;
  background-color: #fff;
}

.datePickers {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid unosLightGrey;
  border-radius: 4px;
  background-color: #fff;
}

.datepick {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.dateButtonBox {
  display: flex;
}

/* search */
.searchBtn {
  width: 50px;
  height: 2rem;
  background-color: #fff;
  outline: none;
  border: 1px solid unosLightGrey;
  border-radius: 12px;
  cursor: pointer;
  margin-left: 12px;
}

.searchBtn:hover {
  border: 1px solid rgba(59, 59, 59, 0.5);
}

/* chart */
.chart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 1rem;
  /* new */
  gap:0;
}

.chartContainer {
  height: 450px;
  padding: 1rem 0.5rem;
}

.chartRightContainer {
  display: flex;
  flex-direction: column;
}

.rankTitle {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  font-weight: 600;
  color: unosBlack;
  padding: 0 0.5rem;
  margin: 1rem 0;
}

.rankList {
  width: 100%;
  height: 360px;
  overflow-y: auto;
  margin: 0 auto;
  padding: 1rem 0;
  padding-top: 0.5rem;
}

.emptyBox {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.salesInfoWrapper {
  width: fit-content;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 20%;
}

.infoTitle {
  font-size: 1.1rem;
  font-weight: 500;
  color: unosBlack;
  margin: 0;
}

.infoTitle span {
  font-size: 0.9em;
}

.infoSubTitle {
  margin: 0;
  color: unosGrey;
  font-weight: 400;
}

.subTitle {
  color: unoslightBlack;
}

.detailTableWrapper {
  height: 500px;
  min-height: 500px;
}

.detailTable {
  width: 100%;
  max-height: 500px;
  margin: 0 auto;
}

.barchart {
  height: 23rem;
}

.pieChart {
  padding-top: 1rem;
}

.rightCell {
  text-align: right;
}

.cardsWrapper {
  width: 100%;
  display: flex;
  font-size: 1rem;
  margin: 0;
  /* margin-top: 1rem; */
}

@media screen and (max-width: 960px) {
  .searchBox {
    flex-direction: column;
  }

  .conditionRow {
    justify-content: flex-start;
  }

  .datepick {
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0;
  }

  .tabWrapper {
    border-bottom: 1px solid unosLightGrey;
  }

  .searchBtn {
    width: 50px;
    margin-left: 8px;
  }

  .chart {
    width: 100%;
    min-height: 360px;
    gap:1rem;
  }

  .salesInfo {
    padding: 1rem 0;
  }

  .chartLeftContainer {
    min-height: 360px;
    padding: 0 0.5rem;
  }

  .chartRightContainer {
    height: 250px;
    flex-direction: row;
    justify-content: center;

    /* new */
    height: auto;
    flex: 1 1;
  }

  .pieChart {
    width: 50%;
    padding: 1rem;
  }

  .rank {
    width: 100%;
    padding: 0 1rem;
  }

  .rankList {
    height: 180px;
  }
  .cardsWrapper {
    width: 50%;
    height: 250px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .mainWrapper {
    padding: 0;
  }

  .info {
    padding-bottom: 0.5rem;
  }

  .conditionRow {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .datepick {
    padding: 0.3rem;
    justify-content: center;
    align-items: center;
  }

  .tabWrapper {
    justify-content: space-around;
  }

  .datepick {
    flex-direction: column;
  }

  .chart {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .salesInfo {
    padding: 0 0.5rem;
  }

  .sales {
    font-size: 0.9rem;
  }

  .dateButtonBox {
    margin-bottom: baseSpace;
  }

  .datePickerBox {
    margin-bottom: baseSpace;
  }

  .datePickerWrapper {
    justify-content: center;
  }

  /* select */
  .select {
    justify-content: center;
    flex-direction: column;
  }

  .searchBtn {
    width: 85%;
    max-width: 400px;
    margin-left: 8px;
  }

  .chartContainer {
    width: 100%;
    display: flex;    
    flex-direction: column;
    justify-content: center;
    
  }

  .chartLeftContainer {
    height: 300px;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
  }

  .chartRightContainer {
    margin-top: 0.2rem;
    padding: 0 1rem;
    height: 250px;
  }

  .rank {
    padding: 0;
  }

  .cardsWrapper {
    margin-top: 0.5rem;
    width: 100%;
    font-size: 14px;
  }

  .infoWrapper {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .infoTitle {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
}
