@value unosWhite, unosLightGrey from '../../common/colors.css';

.listWrapper {
  margin-top: 1rem;
  width: 100%;
  min-height: calc(100vh - 12rem);
}

.list {
  width: 100%;
  height: 3.6rem;
  align-items: center;
  background-color: unosWhite;
  transform: scale(0.99);
  transition: all 100ms ease-in;
  padding: 1rem;
  border-bottom: 1px solid unosLightGrey;
}

.list:hover {
  transform: scale(1);
  max-width: 100%;
  background-color: #f4f4f4;
}

.content {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
}

.title {
  padding: 8px;
  width: 80%;
  cursor: default;
}

.button {
  color: #666;
  text-decoration: none;
  background-color: unosWhite;
  border: 1px solid unosLightGrey;
  padding: 4px 12px;
  cursor: pointer;
  height: 30px;
  min-width: 120px;
}
