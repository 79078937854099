@value textViolet, unosBlack800, borderViolet from '../../common/colors.css';

.tabContent {
  margin-top: 2rem;
}

.section {
  padding: 0 1.8rem;
  margin-bottom: 2rem;
}

.subTitle {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #444;
  font-weight: 500;
}

.subTitle::before {
  display: inline-block;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: textViolet;
  margin-right: 5px;
}

.listBox {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem 1rem;
  margin-bottom: 1.8rem;
  padding-left: 0.5rem;
}

.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionBtns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  margin-bottom: 10px;
}

.btn {
  border: 1px solid borderViolet;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  padding: 4px 9px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 8%);
  color: textViolet;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
}

.btn svg {
  width: 16px;
  height: 16px;
}

.disabledBtn {
  cursor: default;
  color: unosBlack800;
  border: 1px solid unosBlack800;
}

.arrow {
  display: inline-block;
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowDown {
  transform: rotate(90deg);
}

.flexEnd {
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.select {
  padding: 4px 9px;
  background-color: #fff;
  border: 1px solid borderViolet;
  border-radius: 4px;
}

.tableContainer {
  max-width: 90vw;
}

@media screen and (max-width: 480px) {
  .btnRow {
    flex-direction: column;
    justify-content: normal;
    align-items: baseline;
  }

  .select {
    max-width: 250px;
  }
}
