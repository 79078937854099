.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding-top: 4rem;
}

.upBtn {
  position: absolute;
  width: 45px;
  height: 45px;
  z-index: 20;
  right: 20px;
  bottom: 20px;
  border-radius: 45%;
  outline: none;
  border: none;
  color: #fff;
  transition: all 300ms ease;
  cursor: pointer;
}

.upBtn:hover {
  color: #3f51b5;
}
