.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 10;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  min-width: 300px;
  min-height: 200px;
  max-height: 90vh;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 20px, rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset !important
}