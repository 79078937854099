@value unosGreen, unosNavy, unosGrey200, unoslightBlack from '../../common/colors.css';

.box {
  padding-bottom: 0.7rem;
}

.title {
  font-size: 12px;
  padding-bottom: 2px;
  display: inline-block;
  color: unoslightBlack;
}

.contents {
  font-size: 16px;
  font-weight: 500;
}

.submitBtn {
  color: #fff;
  background-color: unosGreen;
}

.submitBtn:hover {
  background-color: #16954f;
}

.cancelBtn {
  color: unoslightBlack;
}
