html,
body,
header {
  overflow: auto;
  padding: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans KR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

h2,
h3,
input,
table {
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* noto-sans-kr-100 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('fonts/noto-sans-kr-v25-korean-100.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('fonts/noto-sans-kr-v25-korean-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/noto-sans-kr-v25-korean-100.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fonts/noto-sans-kr-v25-korean-100.woff')
      format('woff'),
    /* Modern Browsers */ /* Safari, Android, iOS */
      url('fonts/noto-sans-kr-v25-korean-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-300 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/noto-sans-kr-v25-korean-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('fonts/noto-sans-kr-v25-korean-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/noto-sans-kr-v25-korean-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fonts/noto-sans-kr-v25-korean-300.woff')
      format('woff'),
    /* Modern Browsers Safari, Android, iOS */
      url('fonts/noto-sans-kr-v25-korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-regular - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/noto-sans-kr-v21-korean-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('fonts/noto-sans-kr-v21-korean-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/noto-sans-kr-v21-korean-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('fonts/noto-sans-kr-v21-korean-regular.woff') format('woff'),
    /* Modern Browsers */
      url('fonts/noto-sans-kr-v21-korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-500 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/noto-sans-kr-v21-korean-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('fonts/noto-sans-kr-v21-korean-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/noto-sans-kr-v21-korean-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fonts/noto-sans-kr-v21-korean-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('fonts/noto-sans-kr-v21-korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-700 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/noto-sans-kr-v21-korean-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('fonts/noto-sans-kr-v21-korean-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/noto-sans-kr-v21-korean-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fonts/noto-sans-kr-v21-korean-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('fonts/noto-sans-kr-v21-korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
