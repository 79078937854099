/* CKEditor */
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}

.ck.ck-content ul {
  padding-left: 40px;
}

.ck-list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .ck-editor__editable_inline {
  min-height: 400px;
} */
