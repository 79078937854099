@value lightBlack, unosBlue, unosBaseBlue, unosLightGrey from '../../common/colors.css';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  color: #555;
  margin-bottom: 4px;
}

.dateBox {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  border: 1px solid unosLightGrey;
  border-radius: 4px;
}

.dateBox:hover {
  border: 1px solid unosBaseBlue;
}

.pickerBox {
  width: 100%;
  display: flex;
}

.picker {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid unosLightGrey;
  border-radius: 4px;
  padding-right: 4px;
}

.slash {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact {
  width: 100%;
  display: flex;
  align-items: center;
}

.select {
  color: lightBlack;
  border: 1px solid unosLightGrey;
  border-radius: 4px;
  height: 30px;
  margin-right: 4px;
}

.select:hover {
  border: 1px solid unosBaseBlue;
}

.select :focus {
  outline: none;
}

.select:focus-visible {
  outline: none;
}

.inputText {
  width: 100%;
  height: 30px;
  background-color: transparent;
  font-size: 14px;
  padding-left: 12px;
  border: 1px solid unosLightGrey;
  border-radius: 4px;
}

.inputText:focus {
  outline: none;
  border-bottom: 2px solid #3f51b5;
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }

  .title {
    margin-bottom: 0.7rem;
  }
}
