@value unosBaseBlue from '../../common/colors.css';

.button {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 20px;
  right: 20px;
  align-items: center;
  z-index: 30;
  cursor: pointer;
  transition: all 300ms ease-in;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  border-radius: 50%;
  outline: none;
  border: none;
}

.button:hover {
  opacity: 1;
  color: unosBaseBlue;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
