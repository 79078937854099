@value lightGrey, lightBlack, unosBlue, unosNavy, btn-blue from '../../common/colors.css';

div {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 90%;
  max-width: 1200px;
  height: 100%;
  border-radius: 10px;
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(224, 224, 224, 0.4);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.logo {
  width: 80px;
  margin-bottom: 1rem;
}

.description {
  display: block;
}

.text {
  font-weight: 400;
  line-height: 1.8;
}

.form {
  width: 100%;
  max-width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 20;
  background-color: white;
  padding: 1.5rem;
  padding-bottom: 2rem;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.title {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 2em;
  font-weight: 600;
  color: unosNavy;
}

.subTitle {
  font-size: 0.75em;
  color: unosBlue;
}

.input {
  padding: 14px 7px;
  margin: 0.5rem 0;
  border: 1px solid lightGrey;
  color: lightBlack;
  font-size: 16px;
}

.input:focus {
  outline: lightBlack solid 1px;
}

.remember {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.ask {
  display: flex;
  flex-direction: column;
  padding: 0.8em 0;
  font-size: 15px;
}

.ask span {
  line-height: 1.7;
}

.button {
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 1.2rem 0;
  background-color: unosBlue;
  color: white;
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  margin-bottom: 1rem;
}

.prevVerBtn {
  outline: none;
  border: 1px solid unosBlue;
  border-radius: 4px;
  padding: 0.6rem 0;
  background-color: #fff;
  color: unosBlue;
  font-size: 14px;
  font-weight: 200;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.ul {
  padding-left: 10px;
}

@media screen and (max-width: 1300px) {
  .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 960px) {
  .login {
    padding: 0;
    width: 100%;
    max-width: 540px;
    background: none;
    border: none;
  }

  .form {
    max-width: 400px;
  }

  .description {
    padding: 1rem;
  }

  .text {
    margin: 0;
    font-size: 20px;
  }

  .login {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .login {
    min-height: 100vh;
    border-radius: 0;
  }

  .form {
    max-width: 100%;
    min-height: 100vh;
    border-radius: 0;
    padding: 2rem;
  }

  .description {
    display: none;
  }
}

/* POPUP */
.popup {
  flex-direction: column;
  position: absolute;
  z-index: 120;
  top: 15%;
  left: 20%;
  width: 100%;
  max-width: 450px;
  background: #fff;
  padding: 1rem;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 20px,
    rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset !important;
}

.popup h2 {
  padding-bottom: 1rem;
  font-size: 1.05em;
}

.popup p {
  margin-bottom: 1rem;
}

/* popup2 */
.popup2 {
  width: 420px;
  border: solid 1px #e5e6e8;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Pretendard;
  position: relative;
  overflow: hidden;
}

.popup2 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.contents {
  padding: 0 4px;
}

.textContents {
  padding: 1rem;
  /* border-bottom: 10px solid #113967; */
  /* border-left: none;
  border-right: none; */
  margin: 8px 0;
}

.popup2 {
  position: absolute;
  flex-direction: column;
  z-index: 121;
  background: #fff;
  font-size: 14px;
  padding: 0;
  overflow: auto;
}

.popupContainer {
  /* position: absolute;
  top: 0;
  left: 0; */
  padding: 26px 40px 0px;
  width: 100%;
}

.popupTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
}

.popupTitle strong {
  font-weight: 800;
}

.popupSubTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

.popupContents h1 {
  margin: 0;
}

.popupContents p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  margin-top: 1rem;
}

.popupLinkList {
  margin-top: 20px;
}

.popupLinkList li a {
  color: blue;
}

.popupLinkList li a:hover {
  cursor: pointer;
}

.popupLinkList li a:visited {
  color: blue;
}

.popupLinkList li a {
  text-decoration: underline;
}

.linkBtn {
  margin: 4rem 0;
  width: 100%;
  height: 60px;
  background-color: btn-blue;
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.popupBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 28px 24px;
}

.popupBtns button {
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  color: #131313;
  cursor: pointer;
}

.popupBtn {
  color: #616161;
  background-color: #edeff3;
  border: solid 1px #e5e6e8;
}

.blueBtn {
  color: #fff;
  background-color: btn-blue;
  border: solid 1px btn-blue;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 0.3rem;
  padding-left: 2%;
  padding-right: 4%;
}

.buttons button {
  width: 50%;
  background-color: transparent;
  color: #113967;
  border: none;
  padding: 4px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.buttons button:first-child {
  text-align: left;
  border-right: 1px solid #113967;
}

.buttons button:last-child {
  text-align: right;
}

@media screen and (max-width: 600px) {
  .popup {
    height: 100vh;
    top: 0;
    left: 0;
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
  }

  .popup2 {
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    padding: 0;
    border-radius: 0;
  }

  .popup2 img {
    max-width: 320px;
  }

  .popup h2 {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .popup2 {
    width: 100%;
  }

  .popupContainer {
    padding: 60px 20px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popupContents {
    margin-bottom: 30px;
  }

  .popupTitle {
    margin-bottom: 20px;
  }

  .popupContents p {
    max-height: 250px;
    overflow-y: auto;
  }

  .popupBtns button {
    font-size: 14px;
  }
}
