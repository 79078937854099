.titleBox {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  padding: 0.5rem 0;
  margin-top: 0.3rem;
  font-size: 1.2rem;
}

.description {
  width: 100%;
  padding: 0.5rem 1rem;
  padding-bottom: 0;
  font-size: small;
}

@media screen and (max-width: 600px) {
  .titleBox {
    padding: 1rem;
  }

  .title {
    font-size: 1.1rem;
    padding: 0;
  }

  .description {
    padding: 0.5rem 1rem;
    font-size: 12px;
  }
}
